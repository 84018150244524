@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
* {
  margin: 0;
  font-family: 'Poppins', sans-serif;
}

.container-fluid{
  padding-left: 0rem;
  padding-right: 0rem;
  overflow: hidden;
}

/* Header */
.header{
  font-family: 'Roboto', sans-serif;
  background-size: cover;
  background-color: white;
}

nav{
  -moz-box-shadow: 0 0 3px #ccc;
  -webkit-box-shadow: 0 0 3px #ccc;
  box-shadow: 0 0 14px rgb(0 0 0/10%);
}

.navbar{
  background-color: white;
  margin: 2vh 8vw;   
  padding: 0;  
}

.navbar img{
  margin-left:4vw ;
}

.navbar-nav{
  margin: auto 4vw;
}

.navbar a{
  vertical-align: middle!important;
  display: inline-flex;
  color: green!important;
}

.navbar-nav > li{
  padding: 1em;
  vertical-align: middle;
}

.navbar .btn-link, Link{
  text-decoration: none;
  font-size: large;
  font-weight: 600;
  color: red;
}

.navbar .btn-link:hover{
  color: green;
}

.nav-link, .nav-link a{
  text-decoration: none;
  color: red;
}

.nav-link a:hover{
  color: #191970;
}

.nav-link .btn-primary{
  background-color: #191970;
  border: none;
}

.nav-link .btn-primary:hover{
  background-color: green;
  border: none;
}

/* Hero section */
.hero{
  background-color: white;
}

.hero-col{
  padding: 0 50px;
}

.hero-img img{
  padding:7% 15% 4% 2%;
}

.hero-text{
  padding-top: 35%;
}

.hero .bg-image img{
  height: 70vh;
  padding: 8vh;
}

/* Services */
.services{
  display: flex;
  flex-wrap: wrap;
  padding: 5vh 8vw;
}

.services .service-head{
width: 100%;
margin: 8vh 10px;
text-align: center;
}

.services .service-head span{
  color: #fe5970;
  font-weight: 500;
}

.services .service-head h2{
  color: #191970;
  font-weight: 600;
}

.services .service-head p{
  color: #828181;
  margin-bottom: 0;
  padding: 0 25vw;
  font-size: 14px;
}

/* Card Component */
.card{
  align-items: start;
  margin: 10px;
  height: auto;
  max-width: 100%;
  background-color: #fff;
  padding: 25px;
  color: #191970;
  -moz-box-shadow: 0 0 3px #ccc;
  -webkit-box-shadow: 0 0 3px #ccc;
  box-shadow: 0 0 14px rgb(0 0 0/10%);
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  border: none;
}

.card:hover{
  background-color: #191970;
  border: 1px solid #fe5970;
}

.card .card-img{
  border: none;
  max-width: 80px;
  vertical-align: middle;
}

.card .card-body{
  margin: 0;
  padding: 15px 0 5px 0;
}

.card h4{
  font-size: 18px;
  color: #fe5970;
  font-weight: 700;
}

.card p{
  padding:0;
  margin: 0;
  color: #828181;
  margin-bottom: 0;
  font-size: 12px;
}

/* Contact */
.contact{
  padding: 10vh 8vw;
  background-color: whitesmoke;
}

.contact .contact-body{
  background-image: url("./assets/img/contactus.jpg");
  background-repeat: no-repeat;
  height: auto;
  background-position: center; 
  background-repeat: no-repeat; 
  background-size: cover;
 
}

.contact .contact-form{
  border: 2px solid whitesmoke;
  background-color: white;
  padding: 60px;
}

.contact .contact-form input, .contact .contact-form textarea{
  border-radius: 0;
}

.contact .contact-form button{
  border-radius: 0;
  margin: 10px 0 0 10px;
  background-color: #fe5970;
  border:none;
}

.contact .contact-form button:hover{
  background-color: green
}

.contact .contact-form .form-group
  {
    padding: 10px;
}

/* Footer */
.footer{
  margin:0;
  color: orange;
  background-color: white;
  text-align: center;
}

.footer .footer-top{
  padding: 5vh 20vh 10vh; 
}

.footer .social{
  text-align: left;
  padding: 2vh 10vh;
  vertical-align: middle;
  background-color: rgb(73, 69, 69);
  border-bottom: 0.1px solid gray;
}

.footer .social .social-icons{
  text-align: end;
}

.footer .social .social-icons svg{
  display: inline-block;
  justify-content: space-between;
  margin: auto 15px;
}

.footer .about {
  text-align: left;
}

.footer .about p{
  padding-right: 10px;
  font-size: 15px;
}

.footer h5{
  padding: 18px 0;
  text-align: left;
  margin: 0;
}

.footer ul{
  list-style: none;
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  padding: 0;
  margin: 0;
}

.footer ul li{
  padding: 1vh 0;
}

.footer .copyright{
  padding: 2vh;
  border-top: 0.5px solid white;
  background-color: whitesmoke;
}

/* // Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .navbar {
    margin: 5px 10px;
  }
  
  .nav-item a, .navbar-nav>li{
    padding: 0;
  }
  
  .blog-btn{
    margin-left: 10px;
  }
  
  .services{
    padding: 15px;
  }
  
  .services .service-head{
    margin: 5vh 0;
  }
  
  .services .service-head p{
    padding: 0;
  }
  
  .services .service-head h2{
    font-size: 18px;
  }
  
  .contact{
    padding: 4vh 8vw;
  }
  
  .contact .contact-form{
    padding: 10px;
  }

  
  .footer h5{
    margin-top :1.5vh;
  }
  
  .footer .footer-top{
    padding: 3vh 5vh 0 5vh;
  }
  
  .footer .copyright{
    font-size: x-small;
  }
  
  .footer .social{
    padding: 10px;
  }
  
  .footer .social .social-icons{
    text-align: start;
    margin: 0;
  }
}

@media (max-width: 991px){
  .contact .contact-body{
    height: 200px;
  }
}